import React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from 'gatsby'


const Cursos = () => {
  const data = useStaticQuery(graphql`
    query CursosQuery {
      allCursosYaml {
        edges {
          node {
            curso
            content{
              titulo
              objetivo
              emoji
            }
          }
        }
      }
    }
  `)
  const qy = data.allCursosYaml.edges;
  return (
     <div class="popular_courses">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-7">
            <div class="main_title">
              <h2 class="mb-3">Como podemos te ajudar?</h2>
            </div>
          </div>
        </div>
        <div class="row">
          <a id="cursos" />
          {/* single course */}
          <div class="col-lg-12">
            <div class="owl-carousel active_course">
             {qy.map((dt, index) => { 
            return(
              <div class="single_course">
                <div class="course_head">
                  <img class="img-fluid" src={"img/courses/"+dt.node.curso+".jpg"} alt="" />
                </div>
                <div class="course_content">
            <span class="price">{dt.node.content.emoji}</span>
                  <span class="tag mb-4 d-inline-block">Profissionalizantes</span>
                  <h4 class="mb-3">
                  <Link to={"/cursos/"+dt.node.curso}>{dt.node.content.titulo}</Link>
                  </h4>
                  <p className="multi-line-ellipsis">
                  {dt.node.content.objetivo}
                  </p>
                  <div
                    class="course_meta d-flex justify-content-lg-between align-items-lg-center flex-lg-row flex-column mt-4"
                  >
                  </div>
                </div>
              </div>
             )})}
          </div>
        </div>
      </div>
    </div>
    </div>
)};


export default Cursos