import React from "react"
import Countdown from 'react-countdown';
import ContatoForm from './ContatoForm';


const Completionist = () => <span>Fale conosco!</span>;
const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      return <Completionist />;
    } else {
      return (
        <>
            <div className="col clockinner clockinner1">
                <h1 id="horas" className="hours">{hours}</h1>
                <span className="smalltext">horas</span>
            </div>
            <div className="col clockinner clockinner1">
                <h1 id="minutos" className="minutes">{minutes}</h1>
                <span className="smalltext">minutos</span>
            </div>
            <div className="col clockinner clockinner1">
                <h1 id="segundos" className="seconds">{seconds}</h1>
                <span className="smalltext">segundos</span>
            </div>
        </>
        );
    }
  };

const ContatoTimer = () => {
  return (
    <div className="section_gap registration_area">
        <div className="container">
        <div className="row align-items-center">
        <div className="col-lg-7">
            <div className="row clock_sec clockdiv" id="clockdiv">
                <div className="col-lg-12">
                    <h1 className="mb-3">Temos uma oferta para você</h1>
                    <p>
                    Se entrar em contato conosco dentro de 30 minutos você garante um <b>desconto</b> no seu primeiro curso na <b>VitalNet</b>
                    </p>
                </div>
                <Countdown
                    date={Date.now() + 1000*3600/2}
                    renderer={renderer}
                />
            </div>
        </div>
        <div className="col-lg-4 offset-lg-1">
            <div className="register_form">
            <h3>É fácil e rápido</h3>
            <p>Mude seu futuro conosco</p>
            <ContatoForm cta="Garantir Desconto" />
            </div>
        </div>
        </div>
    </div>
</div>

)};


export default ContatoTimer;