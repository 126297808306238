import React from "react"
import Layout from "../components/Layout"
import { Link } from "gatsby"
import Cursos from "../components/Cursos"
import { AnchorLink } from "gatsby-plugin-anchor-links";
import ContatoTimer from "../components/ContatoTimer"

export default function Home() {
  return (
    <Layout breadcrumbs={["Ínicio"]} item="0">
      <section className="home_banner_area">
          <div className="banner_inner">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="banner_content text-center">
                  <p className="text-uppercase">
                    Você no controle de seu futuro
                  </p>
                  <h2 className="text-uppercase mt-4 mb-5">
                    Qualificamos você para o <br / >mercado de trabalho
                  </h2>
                  <div>
                    <AnchorLink to="/#cursos" className="primary-btn2 mb-3 mb-sm-0">Saiba mais</AnchorLink>
                    <Link className="primary-btn ml-sm-3 ml-0" to="/contato">Clique para falar conosco</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </section>
      <section className="feature_area section_gap_top">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="main_title">
                <h2 className="mb-3">Cursos profissionalizantes não são mais diferenciais,<br />mas, sim, <b>essenciais</b> para o primeiro emprego</h2>
                <p>
                  Nós sabemos a importância de um curso para conseguir um bom emprego. A VitalNet está há mais de 20 anos no mercado de Guarapuava e somos responsáveis por transformar milhares de estudantes em profissionais.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="single_feature">
                <div className="icon"><span className="flaticon-student"></span></div>
                <div className="desc">
                  <h4 className="mt-3 mb-2">Estude em uma universidade</h4>
                  <p>
                    Somos parceiros da <b>UNIP</b> e podemos te ajudar a ingressar em uma das melhores universidades do Brasil 
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="single_feature">
                <div className="icon"><span className="flaticon-book"></span></div>
                <div className="desc">
                  <h4 className="mt-3 mb-2">Aprendizado do seu jeito</h4>
                  <p>
                    Você terá acesso ao material de aula na sua casa e poderá fazer as aulas on-line e presenciais.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="single_feature">
                <div className="icon"><span className="flaticon-earth"></span></div>
                <div className="desc">
                  <h4 className="mt-3 mb-2">Aprenda o que é importante</h4>
                  <p>
                    Nossos cursos focam no que realmente é utilizado no mercado. Sem enrolação e direto ao ponto.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Cursos />
      <ContatoTimer />
   </Layout>
  );
}